import React from 'react';
import { MaxWidthText } from '@allenai/varnish2/components';

export const About = () => {
    return (
        <MaxWidthText>
            <h2>About WildChat and WildLlama</h2>
            <p>
                The WildChat dataset and the WildLlama model are developed to advance conversational AI research.
            </p>
            
            <h3>WildChat Dataset</h3>
            <p>
                The <strong>WildChat</strong> dataset is a collection of 1,009,245 conversations between human users and ChatGPT, showcasing a diverse range of conversational scenarios.
                <br/>
                <ul>
                <li>License: <a href="https://opendatacommons.org/licenses/by/1-0/">ODC-BY</a></li>
                <li>Dataset hosted on HuggingFace: <a href="https://huggingface.co/datasets/allenai/WildChat-1M">WildChat Dataset</a></li>
                </ul>
            </p>
            
            <h3>WildLlama Models</h3>
            <p>
                The <strong>WildLlama-7b-user-assistant</strong> model is distinct in its ability to predict both user prompts and assistant responses, a result of fine-tuning <a href="https://huggingface.co/meta-llama/Llama-2-7b-hf">Meta's Llama-2</a>, which is licensed under the <a href="https://ai.meta.com/resources/models-and-libraries/llama-downloads/">Llama 2 License</a>, on the WildChat dataset.
                <br/>
                For specific applications prioritizing assistant response quality, the <strong>WildLlama-7b-assistant-only</strong> variant, optimized for generating assistant responses, is recommended.
                <br/>
                <ul>
                <li>License: <a href="https://allenai.org/licenses/impact-mr">AI2 ImpACT License - Medium Risk</a></li>
                <li>Models hosted on HuggingFace: <a href="https://huggingface.co/allenai/WildLlama-7b-user-assistant">WildLlama-7b-user-assistant</a> and <a href="https://huggingface.co/allenai/WildLlama-7b-assistant-only">WildLlama-7b-assistant-only</a></li>
                </ul>
            </p>
            
            <p>
                The paper, "<a href="https://arxiv.org/abs/2405.01470">WildChat: 1M ChatGPT Interaction Logs in the Wild</a>", by Wenting Zhao, Xiang Ren, Jack Hessel, Claire Cardie, Yejin Choi, and Yuntian Deng, presents more details about the WildChat dataset and the WildLlama model.
            </p>

            <p>
                 The design and structure of this website are adapted from Taylor Sorensen's <a href="https://kaleido.allenai.org/">Kaleido project</a>.
            </p>

        </MaxWidthText>
    );
};
