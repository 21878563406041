import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useFetch, { CachePolicies } from 'use-http';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Select,
    Alert,
    AlertTitle,
    SelectChangeEvent,
    Collapse,
    MenuItem,
    FormHelperText,
    Box,
    Input,
    Stack,
    useMediaQuery,
    useTheme,
    InputLabel,
    FormControl,
    Typography,
    Tab,
    Tabs,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MaxWidthText } from '@allenai/varnish2/components';

import { Answer } from '../api/Answer';
import { Query } from '../api/Query';
import './Conversation.css';

interface Message {
    role: string;
    content: string;
}

/**
 * This type defines the different values that will be accepted from the form's inputs.
 * The names of the keys (Eg. 'question', 'choices') must match
 * the HTML input's declared 'name' attribute.
 *
 * Eg:
 * ``` <Form.Item label="Question:" name="question"> ... </Form.Item>
 *
 * In this example, we aligned the
 * form's 'name' attributes with the keys of the 'Query' type that is used to query the API.
 */
type FormValue = Partial<Query>;

export const Home = () => {
    /**
     * These are react hooks. You can read more about hooks here:
     * @see https://legacy.reactjs.org/docs/hooks-intro.html
     */
    const navigate = useNavigate();
    const location = useLocation();
    const vtheme = useTheme();
    const smallerThanSm = useMediaQuery(vtheme.breakpoints.down('sm'));

    const [conversation, setConversation] = useState<Message[]>([]);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        fetch(`/api/conversation?index=${index}`)
            .then(response => response.json())
            .then(data => setConversation(data as Message[]))
            .catch(error => console.error('Error:', error));
    }, [index]);

    const handlePrev = () => {
        setIndex(index - 1);
    };

    const handleNext = () => {
        setIndex(index + 1);
    };


    const [marginTop, setMarginTop] = React.useState(0);
    const topRef = React.useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(() => {
        const savedState = localStorage.getItem('kaleido_collapsed_state');
        return savedState ? JSON.parse(savedState) : true;
    });
    const handleCollapseToggle = () => {
        const newState = !isExpanded;
        setIsExpanded(newState);
        localStorage.setItem('kaleido_collapsed_state', JSON.stringify(newState));
    };

    return (
        <div>
            <h1>WildChat</h1>
            <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={smallerThanSm ? 12 : 8} ref={topRef}>

            <Button onClick={handleCollapseToggle}>
                {isExpanded ? 'Hide Details' : 'Show Details'}
            </Button>

            <Collapse in={isExpanded}>
                <MaxWidthText as="p">
                The WildChat Dataset is a corpus of 1 million real-world user-ChatGPT interactions, characterized by a wide range of languages and a diversity of user prompts. It was constructed by offering free access to ChatGPT and GPT-4 in exchange for consensual chat history collection. Using this dataset, we finetuned <a href="https://huggingface.co/meta-llama/Llama-2-7b-hf">Meta's Llama-2</a> and created WildLlama-7b-user-assistant, a chatbot which is able to predict both user prompts and assistant responses.
                    <br />
                    <span style={{ fontWeight: 900, fontSize: '16px' }}>
                        {' '}
                        To learn more:
                        <a
                            href="https://huggingface.co/datasets/allenai/WildChat-1M"
                            target="_blank"
                            rel="noreferrer">
                            {' '}
                            dataset{' '}
                        </a>{' '}
                        /
                        <a
                            href="https://huggingface.co/allenai/WildLlama-7b-user-assistant"
                            target="_blank"
                            rel="noreferrer">
                            {' '}
                            model{' '}
                        </a>{' '}
                        /
                        <a
                            href="https://arxiv.org/abs/2405.01470"
                            target="_blank"
                            rel="noreferrer">
                            {' '}
                            paper{' '}
                        </a>{' '}
                        /
                        <a
                            href="https://wildchat.yuntiandeng.com"
                            target="_blank"
                            rel="noreferrer">
                            {' '}
                            interactive search tool{' '}
                        </a>{' '}
                    </span>
                </MaxWidthText>
            </Collapse>
            <MaxWidthText>
                <div className="chat-container">
                    <div className="navigation mt-3">
                        <Button onClick={handlePrev}>Prev Example</Button>
                        <Button onClick={handleNext}>Next Example</Button>
                    </div>
                    {conversation.map((msg, idx) => (
                        <div key={idx} className={`messages ${msg.role === 'user' ? 'mine' : 'yours'}`}>
                            <div className="message last">
                                {msg.content.split('\n').map((line, key) => (
                                    <React.Fragment key={key}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </MaxWidthText>
            </Grid>
            {!smallerThanSm &&  (
                <Grid item xs={4}>
                    {/* Add in FAQ title*/}
                    <Typography variant="h5">FAQ</Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<Typography variant="h6">+</Typography>}>
                            <Typography>Q: Which versions of GPT are used, and why do some responses to inquiries about the GPT version indicate "GPT-3"?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            A: To collect the dataset, we employ two models: ChatGPT (GPT-3.5 Turbo) and GPT-4. During most of our data collection period, GPT-4 was trained with information available up to September 2021, preceding its release. Consequently, the chatbot might self-identify as GPT-3 due to the training data cut-off. However, a notable observation is that when interacting through the ChatGPT web interface, GPT-4 accurately recognizes and states its version. This accuracy could be attributed to a system prompt or a hidden context underlying the web interface.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<Typography variant="h6">+</Typography>}>
                            <Typography>Q: How was the WildChat dataset created?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                A: To create the WildChat dataset, we deployed two chatbot services, <a href="https://huggingface.co/spaces/yuntian-deng/ChatGPT">one utilizing the GPT-3.5 Turbo API</a> and <a href="https://huggingface.co/spaces/yuntian-deng/ChatGPT4">another leveraging the GPT-4 API</a>. Both services were hosted on Hugging Face Spaces and made available to the public. Importantly, users were not required to create an account or provide personal information to access our services. The dataset currently encompasses interactions from April 9, 2023, to April 30, 2024.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<Typography variant="h6">+</Typography>}>
                            <Typography>Q: What are some limitations of the WildChat dataset and the WildLlama model?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                A:
                                <ul>
                                    <li>
                                        User Demographics: Our chatbot service, hosted on Hugging Face Spaces, predominantly attracts developers or individuals connected to the IT sector. Consequently, the user base may not accurately represent the broader population. This specificity influences the dataset, often reflecting conversations with a technological tilt, such as coding queries.
                                    </li>
                                    <li>
                                        Toxicity and Selection Bias: The anonymity offered by our chatbot service might encourage users to engage in conversations they would avoid on platforms requiring registration.
                                    </li>
                                    <li>
                                        Bias in WildLlama: The WildLlama model, finetuned on machine-generated data from ChatGPT and GPT-4, may inherit biases inherent in its parent models.
                                    </li>
                                    <li>
                                        Usage Constraints for WildLlama: WildLlama is designed for research purposes and not for practical advice or applications involving direct human interaction. Its use should be confined to academic and exploratory contexts.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
            )}
        </Grid>
        </div>
    );
};

/**
 * The definition below creates a component that we can use in the render
 * function above that have extended / customized CSS attached to them.
 * Learn more about styled components:
 * @see https://www.styled-components.com/
 *
 *
 * CSS is used to modify the display of HTML elements. If you're not familiar
 * with it here's quick introduction:
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS
 */
const FormStack = styled(Stack)`
    max-width: 600px;
`;

/**
 * Matches style for helperText.
 * Needed because Select does not have helperText.
 */
const FormHelperTextError = styled(FormHelperText).attrs({ error: true })`
    && {
        margin: 3px 14px 0;
    }
`;

/**
 * Makes button not full width
 */
const Button = styled(LoadingButton)`
    width: 130px;
`;
